<template>
    <section>
       <div class="">
          <b-modal
             id="modal-add"
             cancel-variant="outline-secondary"
             ok-title="Créer"
             cancel-title="Annuler"
             centered
             title="Ajouter un nouvel domaine"
             @ok="addDomaine"
          >
             <b-form-group label-for="register-nom">
                <label for="libelleID"
                   >Libelle <span class="p-0 text-danger h6"> *</span></label
                >
 
                <b-form-input
                   id="libelleID"
                   @input="validateDomaine(input.model)"
                   v-model="input.model.libelle"
                   name="libelle"
                   placeholder="facturations"
                />
                <small class="text-danger" v-if="input.error.path === 'libelle'">
                   {{ input.error.message }}
                </small>
             </b-form-group>
             
 
             <template #modal-footer>
                <b-button
                   :disabled="state.loading === true ? true : false"
                   variant="primary"
                   @click.stop.prevent="addDomaine"
                >
                   <span v-if="state.loading === false">Ajouter</span>
                   <b-spinner
                      v-if="state.loading === true"
                      label="Spinning"
                   ></b-spinner>
                </b-button>
             </template>
          </b-modal>
 
          <b-modal
             id="modal-edit"
             cancel-variant="outline-secondary"
             ok-title="Modifier"
             cancel-title="Annuler"
             centered
             title="Modifier une domaine"
             @ok="editDomaine"
          >
             <b-form-group label-for="libelleID">
                <label for="libelleID"
                   >Libelle <span class="p-0 text-danger h6"> *</span></label
                >
 
                <b-form-input
                   id="libelleID"
                   @input="validateDomaine(input.model)"
                   v-model="input.model.libelle"
                   name="libelle"
                   placeholder="facturations"
                />
                <small class="text-danger" v-if="input.error.path === 'libelle'">
                   {{ input.error.message }}
                </small>
             </b-form-group>
 
             <template #modal-footer>
                <b-button
                   :disabled="state.loading === true ? true : false"
                   variant="primary"
                   @click.stop.prevent="editDomaine"
                >
                   <span v-if="state.loading === false">Ajouter</span>
                   <b-spinner
                      v-if="state.loading === true"
                      label="Spinning"
                   ></b-spinner>
                </b-button>
             </template>
          </b-modal>
 
          <!-- Tableau pour afficher les utilisateurs-->
          <div class="tableau">
             <b-card no-body class="">
                <b-row class="px-2 py-2">
                   <!-- Per Page -->
                   <b-col
                      cols="12"
                      md="6"
                      class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mt-1"
                   >
                      <label>Entrées</label>
                      <v-select
                         v-model="state.perPage"
                         :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                         :options="state.perPageOptions"
                         :clearable="false"
                         class="per-page-selector d-inline-block ml-50 mr-1"
                      />
 
                      <b-button variant="primary" v-b-modal.modal-add>
                         <div class="flex gap-2">
                            <feather-icon icon="PlusIcon" class="mx-auto" />
                            <span>Créer une domaine</span>
                         </div>
                      </b-button>
                   </b-col>
 
                   <!-- Search -->
                   <b-col cols="12" md="4" class="mt-1">
                      <div class="d-flex align-items-center justify-content-end">
                         <b-input-group class="input-group-merge">
                            <b-form-input
                               v-model="state.filtreDomaine"
                               class="d-inline-block mr-1"
                               placeholder="Rechercher par : Nom, role, contact"
                            />
                         </b-input-group>
                      </div>
                   </b-col>
                </b-row>



                <q-loader-table
                  :success="state.success"
                  :empty="state.empty"
                  :warring="state.warring"
               />


                <b-table
                   hover
                   responsive
                   primary-key="id"
                   :per-page="state.perPage"
                   :current-page="state.currentPage"
                   :items="Domaine"
                   :fields="tableColumns"
                   :filter="state.filtreDomaine"
                   show-empty
                   empty-text=""
                   class="bg-white"
                   v-if="state.success === true"
                >
                   <!-- new butt -->
 
                   <template #cell(actions)="data">
                      <b-button
                         v-b-modal.modal-edit
                         v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                         variant="success"
                         class="btn-icon rounded-circle mr-1"
                         @click="getCurrentDataToEdit(data.item)"
                      >
                         <feather-icon icon="Edit3Icon" />
                      </b-button>
 
                      <b-button
                         v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                         variant="danger"
                         class="btn-icon rounded-circle"
                         @click="deleteDomaine(data.item.id)"
                      >
                         <feather-icon icon="Trash2Icon" />
                      </b-button>
                   </template>
                </b-table>
 
                <!-- Les boutons de pagination -->
                <div class="mx-2 mb-2 pb-1">
                   <b-row>
                      <b-col
                         cols="12"
                         sm="6"
                         class="d-flex align-items-center justify-content-center justify-content-sm-start"
                      >
                         <span class="text-muted"></span>
                      </b-col>
                      <!-- Pagination -->
                      <b-col
                         cols="12"
                         sm="6"
                         class="d-flex align-items-center justify-content-center justify-content-sm-end"
                      >
                         <b-pagination
                            v-model="state.currentPage"
                            :total-rows="Domaine.length"
                            :per-page="state.perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                         >
                            <template #prev-text>
                               <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                               <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                         </b-pagination>
                      </b-col>
                   </b-row>
                </div>
             </b-card>
          </div>
       </div>
       {{__NEXT}}
    </section>
 </template>
 
 <script>
 import {
    BRow,
    BCol,
    BModal,
    BTable,
    BPagination,
    BFormInput,
    BFormGroup,
    BButton,
    VBModal,
    BForm,
    BLink,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BFormTextarea,
    BBadge,
    VBToggle,
    BCard,
    BSpinner,
 } from 'bootstrap-vue';
 import { heightTransition } from '@core/mixins/ui/transition';
 import Ripple from 'vue-ripple-directive';
 import { required, email } from '@validations';
 import { ValidationProvider, ValidationObserver } from 'vee-validate';
 import vSelect from 'vue-select';
 import URL from '@/views/pages/request/index';
 import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
 import QLoaderTable from '@/components/__partials/loaders/qLoaderTable.vue';
 import axios from 'axios';
 import flatPickr from 'vue-flatpickr-component';
 import { computed, onMounted, reactive, ref } from '@vue/composition-api';
 
 export default {
    components: {
      QLoaderTable,
       BSpinner,
       ToastificationContent,
       flatPickr,
       vSelect,
       VBToggle,
       BRow,
       BCol,
       BFormInput,
       BButton,
       BModal,
       BTable,
       BPagination,
       BCard,
       BFormGroup,
       VBModal,
       BForm,
       BImg,
       BLink,
       BForm,
       BFormCheckbox,
       BInputGroup,
       BFormTextarea,
       BInputGroupAppend,
       BBadge,
 
       // validations
       ValidationProvider,
       ValidationObserver,
    },
    directives: {
       Ripple,
       'b-toggle': VBToggle,
    },
    mixins: [heightTransition],
    setup(props, { root }) {
       const state = reactive({
          loading: false,
          currentPage: 1,
          perPage: 30,
          perPageOptions: [30, 50, 100, 200, 500],
          filtreDomaine: '',
          success: false,
         empty: false,
         warring: false,
         loading: false,
       });
 
       const input = reactive({
          model: {
             id: 0,
             libelle: '',
          },
          error: {
             message: '',
             path: '',
          },
       });
 
       const tableColumns = reactive([
          { key: 'code', label: 'code' },
          { key: 'libelle', label: 'libelle' },
          { key: 'actions' },
       ]);
       const Domaine = ref([]);
       const GIVE = ref(false);
 
       // To started page
       onMounted(() => {
          getDomaine();
       });

       const __NEXT = computed(() => {
         if (GIVE.value === true) {
            Domaine.value.length === 0
               ? ((state.empty = true), (state.success = false))
               : ((state.success = true), (state.empty = false));
         }
         return;
      });
 
       // Getting all Domaine
       const getDomaine = async () => {
          try {
             const { data } = await axios.get(URL.DOMAINE_PROSPECTION_LIST);
             if (data) {
                console.log(data);
                Domaine.value = data.domaine;
                Domaine.value.reverse();
                GIVE.value = true;
             }
          } catch (error) {
             console.log(error);
          }
       };
 
       const validateDomaine = async (input) => {
          if (input.libelle === '') {
             return {
                error: {
                   path: 'libelle',
                   message: 'Le champs libelle dois etre remplir !',
                },
             };
          }
 
          return {
             error: null,
          };
       };
 
       // Add a new Domaine
       const addDomaine = async () => {
          state.loading = true;
          try {
             const dataDomaine = {
                libelle: input.model.libelle,
             };
 
             const { error } = await validateDomaine(dataDomaine);
 
             if (error) {
                input.error.path = error.path;
                input.error.message = error.message;
                return (state.loading = false);
             }
 
             const { data } = await axios.post(
                URL.DOMAINE_PROSPECTION_CREATE,
                dataDomaine
             );
             if (data) {
                console.log(data);
                Domaine.value.unshift(data.domaine);
                root.$bvModal.hide('modal-add');
                state.loading = false;
             }
          } catch (error) {
             state.loading = false;
             console.log(error);
          }
       };
 
       // get current data to update
       const getCurrentDataToEdit = (data) => {
          input.model.id = data.id;
          input.model.libelle = data.libelle;
       };
       // Edit a specific Domaine
       const editDomaine = async () => {
          state.loading = true;
          try {
             const dataDomaine = {
                id: input.model.id,
                libelle: input.model.libelle,
             };
 
             const { error } = await validateDomaine(dataDomaine);
 
             if (error) {
                input.error.path = error.path;
                input.error.message = error.message;
                return (state.loading = false);
             }
 
             const { data } = await axios.post(
                URL.DOMAINE_PROSPECTION_UPDATE,
                dataDomaine
             );
             if (data) {
                console.log(data);
                Domaine.value.forEach((el) => {
                   if (el.id === input.model.id) {
                      el.libelle = input.model.libelle;
                   }
                });
                root.$bvModal.hide('modal-edit');
                state.loading = false;
             }
          } catch (error) {
             state.loading = false;
             console.log(error);
          }
       };
 
       // Delete specific Domaine
       const deleteDomaine = (id) => {
          root.$swal({
             title: 'Êtes vous sûr?',
             text: 'Cette domaine sera supprimé définitivement !',
             icon: 'warning',
             showCancelButton: true,
             confirmButtonText: 'Oui, supprimer-le!',
             customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
             },
             buttonsStyling: false,
          }).then(async(result) => {
             if (result.value) {
               
 
               try {
 
                 const dataDelete = {
                   id: id
                 }
                 const {data} = await axios.post(URL.DOMAINE_PROSPECTION_DELETE, dataDelete)
                 if(data){
                   Domaine.value = Domaine.value.filter((item) => {
                     return item.id !== id
                   })
                   GIVE.value = true;
                   console.log(data)
                 }
                 
               } catch (error) {
                 console.log(error)
               }
 
             }
          });
       };
 
       return {
          state,
          tableColumns,
          Domaine,
          addDomaine,
          validateDomaine,
          editDomaine,
          getCurrentDataToEdit,
          deleteDomaine,
          input,
          __NEXT
       };
    },
 };
 </script>
 
 <style lang="scss">
 @import '@core/scss/vue/libs/vue-select.scss';
 @import '@core/scss/vue/libs/vue-flatpicker.scss';
 </style>
 